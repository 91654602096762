class HomeCarousel {
    constructor(prevArw, nextArw) {
        this.options = {
            slidesToShow: 2,
            dots: false,
            speed: 800,
            infinite: false,
            prevArrow: prevArw,
            nextArrow: nextArw,
            autoplay: true,
            centerPadding: '0px',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        }
    }

    init() {
        $('.home-carousel-wrapper').slick(this.options);
    }

    refresh() {
        $('.home-carousel-wrapper').slick('refresh');
    }
}

export default HomeCarousel;