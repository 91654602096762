class Slider {
  constructor() {}

  prevArw() {
    return '<button type="button" class="navi navi-prev"><i class="fas fa-angle-left"></i></button>';
  }

  prevArwImg() {
    return '<button type="button" class="navi navi-img navi-prev"></button>';
  }

  nextArw() {
    return '<button type="button" class="navi navi-next"><i class="fas fa-angle-right"></i></button>';
  }
  nextArwImg() {
    return '<button type="button" class="navi navi-img navi-next"></button>';
  }
}

export default Slider;