require('overlayscrollbars');

class Scrollbar {
    constructor() {}

    init() {
        $(function () {
            //The passed argument has to be at least a empty object or a object with your desired options
            //OverlayScrollbars(document.querySelectorAll('body'), {});
        });
    }

}

export default Scrollbar;