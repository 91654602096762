class Modules {
  constructor() {}

  onDocumentReady() {
    if ($('#contact_subject').length) {
      import('./contact_us_block/contact_us_block').then(({
        default: contactBlock
      }) => {
        contactBlock();
      })
    }
  }
}

export default Modules;