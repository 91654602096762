var slick = require('../../vendor/slick.min.js');

import HeaderSlider from '../components/header_slider/header_slider';
import SliderVOne from '../components/sliderV1/sliderV1';
import LogoSlider from '../components/sliderV2/logoSlider';
import CommentsSlider from '../components/sliderV4/commentsSlider';
import HomeCarousel from '../components/home_carousel/home_carousel';
import Carousel from '../components/carousel/carousel';
import Utilities from '../utilities/utilities';

const headerSlider = new HeaderSlider();
const commentsSlider = new CommentsSlider();
const sliderVOne = new SliderVOne();
const logoSlider = new LogoSlider();
const homeCarousel = new HomeCarousel;
const carousel = new Carousel;
const utility = new Utilities;

class FeatureWidget {
    constructor() {
        this.fwContainer = document.createElement('div');
        this.featureWidget = document.createElement('div');

        this.sliderRefresh = () => {
            headerSlider.refresh();
            sliderVOne.firstSliderRefresh();
            sliderVOne.secondSliderRefresh();
            sliderVOne.thirdSliderRefresh();
            logoSlider.refresh();
            commentsSlider.refresh();
            homeCarousel.refresh();
            carousel.refresh();
            // Slider.referencesSlider('refresh');
        }

        /*this.itemHeightRefresh = () => {
            if ($('.home-carousel-wrapper').length) {
                $('.carousel-item-title').css('height', 'unset')
                $('.carousel-item-desc').css('height', 'unset');
                $('.carousel-item-link').css('height', 'unset');
                utility.setEqualHeight('.carousel-item-title');
                utility.setEqualHeight('.carousel-item-desc');
                utility.setEqualHeight('.carousel-item-link');
            }

            if ($('.group_carousel_init').length) {
                $('.carousel-item-title').css('height', 'unset')
                $('.carousel-item-desc').css('height', 'unset');
                $('.carousel-item-link').css('height', 'unset');
                utility.setEqualHeight('.carousel-item-title');
                utility.setEqualHeight('.carousel-item-desc');
                utility.setEqualHeight('.carousel-item-link');
            }
        }*/

        this.sliderSetOptions = (sliderEl, sliderOptions) => {
            if (sliderEl.length) {
                sliderEl.slick('slickSetOption', sliderOptions, true);
            }
        }
    }

    init() {
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = function (callback, thisArg) {
                thisArg = thisArg || window;
                for (var i = 0; i < this.length; i++) {
                    callback.call(thisArg, this[i], i, this);
                }
            };
        }

        // Production steps of ECMA-262, Edition 6, 22.1.2.1
        if (!Array.from) {
            Array.from = (function () {
                var toStr = Object.prototype.toString;
                var isCallable = function (fn) {
                    return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
                };
                var toInteger = function (value) {
                    var number = Number(value);
                    if (isNaN(number)) {
                        return 0;
                    }
                    if (number === 0 || !isFinite(number)) {
                        return number;
                    }
                    return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
                };
                var maxSafeInteger = Math.pow(2, 53) - 1;
                var toLength = function (value) {
                    var len = toInteger(value);
                    return Math.min(Math.max(len, 0), maxSafeInteger);
                };

                // The length property of the from method is 1.
                return function from(arrayLike /*, mapFn, thisArg */) {
                    // 1. Let C be the this value.
                    var C = this;

                    // 2. Let items be ToObject(arrayLike).
                    var items = Object(arrayLike);

                    // 3. ReturnIfAbrupt(items).
                    if (arrayLike == null) {
                        throw new TypeError('Array.from requires an array-like object - not null or undefined');
                    }

                    // 4. If mapfn is undefined, then let mapping be false.
                    var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                    var T;
                    if (typeof mapFn !== 'undefined') {
                        // 5. else
                        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                        if (!isCallable(mapFn)) {
                            throw new TypeError('Array.from: when provided, the second argument must be a function');
                        }

                        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                        if (arguments.length > 2) {
                            T = arguments[2];
                        }
                    }

                    // 10. Let lenValue be Get(items, "length").
                    // 11. Let len be ToLength(lenValue).
                    var len = toLength(items.length);

                    // 13. If IsConstructor(C) is true, then
                    // 13. a. Let A be the result of calling the [[Construct]] internal method 
                    // of C with an argument list containing the single item len.
                    // 14. a. Else, Let A be ArrayCreate(len).
                    var A = isCallable(C) ? Object(new C(len)) : new Array(len);

                    // 16. Let k be 0.
                    var k = 0;
                    // 17. Repeat, while k < len… (also steps a - h)
                    var kValue;
                    while (k < len) {
                        kValue = items[k];
                        if (mapFn) {
                            A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                        } else {
                            A[k] = kValue;
                        }
                        k += 1;
                    }
                    // 18. Let putStatus be Put(A, "length", len, true).
                    A.length = len;
                    // 20. Return A.
                    return A;
                };
            }());
        }

        this.fwContainer.classList.add('fw-container');
        this.featureWidget.classList.add('fw-wrapper');
        document.querySelector('body').appendChild(this.fwContainer);
        document.querySelector('.fw-container').appendChild(this.featureWidget);
        this.createCogwheel();
        this.addBtn();
        this.getContainers();
        this.determineGroupView();
        this.selectView();
        // this.createColumnView();
    }

    createCogwheel() {
        this.cogwheel = document.createElement('div');
        this.cogwheel.classList.add('fw-toggler');
        this.cogwheel.innerHTML = `<i class="fa fa-cog"></i>`;
        document.querySelector('.fw-container').appendChild(this.cogwheel);
        this.cogwheel.addEventListener('click', () => {
            this.cogwheel.classList.toggle('active');
            this.featureWidget.classList.toggle('active');

            if (this.featureWidget.classList.contains('active')) {
                this.featureWidget.style.width = 'auto';
                this.featureWidget.style.visibility = 'visible';
                this.featureWidget.style.padding = '20px';
            } else {
                this.featureWidget.style.width = 0;
                this.featureWidget.style.visibility = 'hidden';
                this.featureWidget.style.padding = 0;
            }
        });
    }

    getContainers() {
        let section = document.querySelectorAll('.section');
        section.forEach((el, i) => {
            let sectionChildren = Array.from(el.children);
            sectionChildren.forEach((scChild) => {
                if (scChild.classList.contains('container') || scChild.classList.contains('container-fluid')) {
                    this.containerBtn.addEventListener('click', () => {
                        scChild.classList.remove('container-fluid');
                        scChild.classList.add('container');
                        this.sliderRefresh();
                        this.itemHeightRefresh();
                        this.sliderSetOptions($('.logo_slider_init'), {
                            slidesToShow: 4,
                            slidesToScroll: 4
                        })
                    });

                    this.containerFluidBtn.addEventListener('click', () => {
                        scChild.classList.remove('container');
                        scChild.classList.add('container-fluid');
                        this.sliderRefresh();
                        this.itemHeightRefresh();
                        this.sliderSetOptions($('.logo_slider_init'), {
                            slidesToShow: 7,
                            slidesToScroll: 7
                        })
                    });

                } else {
                    let wrapperChild = Array.from(scChild.children);
                    wrapperChild.forEach(subChild => {
                        if (subChild.classList.contains('container') || subChild.classList.contains('container-fluid')) {
                            this.containerBtn.addEventListener('click', () => {
                                subChild.classList.remove('container-fluid');
                                subChild.classList.add('container');
                                this.sliderRefresh();
                                this.itemHeightRefresh();
                            });

                            this.containerFluidBtn.addEventListener('click', () => {
                                subChild.classList.remove('container');
                                subChild.classList.add('container-fluid');
                                this.sliderRefresh();
                                this.itemHeightRefresh();
                            });
                        } else {
                            let groupChild = Array.from(subChild.children);
                            groupChild.forEach(grpChild => {
                                if (grpChild.classList.contains('container') || grpChild.classList.contains('container-fluid')) {
                                    this.containerBtn.addEventListener('click', () => {
                                        grpChild.classList.remove('container-fluid');
                                        grpChild.classList.add('container');
                                        this.sliderRefresh();
                                        this.itemHeightRefresh();
                                    });
                                    this.containerFluidBtn.addEventListener('click', () => {
                                        grpChild.classList.remove('container');
                                        grpChild.classList.add('container-fluid');
                                        this.sliderRefresh();
                                        this.itemHeightRefresh();
                                    });
                                }
                            });
                        }
                    });
                }
            })
        });
    }

    determineGroupView() {
        if ($('.group-view-section').length) {
            this.groupWrapper = document.createElement('div');
            this.groupWrapper.classList.add('fw-group-view-wrapper');
            this.groupViewContent = `
                <span>Odaberite skupni prikaz</span><select id="groupViewSelection">
                <option value="grid">Grid prikaz</option>
                <option value="carousel">Carousel prikaz </option>
                <option value="list">List prikaz</option>
                </select>`;

            this.groupWrapper.innerHTML = this.groupViewContent;

            document.querySelector('.fw-wrapper').appendChild(this.groupWrapper);
            let groupViewEvent = document.getElementById('groupViewSelection');
            groupViewEvent.addEventListener('change', this.determineGroupSelection)
            this.determineGroupSelection();
        }
    }

    determineGroupSelection() {
        let groupViewType = document.getElementById('groupViewSelection');

        if (groupViewType.options[groupViewType.selectedIndex].value == 'grid') {
            $('.list_component_wrapper').hide(300);
            $('.carousel_component_wrapper').hide(300);
            $('.grid_component_wrapper').show(300);
        }

        /*if (groupViewType.options[groupViewType.selectedIndex].value == 'carousel') {
            $('.list_component_wrapper').hide(300);
            $('.grid_component_wrapper').hide(300);
            $('.carousel_component_wrapper').show(300);
            setTimeout(() => {
                if ($('.group_carousel_init').length) {
                    // $('.group_carousel_init').slick('refresh');
                    carousel.refresh();
                    $('.carousel-item-title').css('height', 'unset')
                    $('.carousel-item-desc').css('height', 'unset');
                    $('.carousel-item-link').css('height', 'unset');
                    utility.setEqualHeight('.carousel-item-title');
                    utility.setEqualHeight('.carousel-item-desc');
                    utility.setEqualHeight('.carousel-item-link');
                }
            }, 400);
        }*/

        if (groupViewType.options[groupViewType.selectedIndex].value == 'list') {
            $('.grid_component_wrapper').hide(300);
            $('.carousel_component_wrapper').hide(300);
            $('.list_component_wrapper').show(300);
        }
    }

    addBtn() {
        this.containerBtn = document.createElement('button');
        this.containerFluidBtn = document.createElement('button');
        this.containerBtn.classList.add('btn', 'fw-btn', 'container-btn');
        this.containerFluidBtn.classList.add('btn', 'fw-btn', 'container-fluid-btn');
        this.containerBtn.textContent = 'Boxed';
        this.containerFluidBtn.textContent = 'Full';
        document.querySelector('.fw-wrapper').appendChild(this.containerBtn);

        this.containerBtn.addEventListener('click', () => {
            this.containerFluidBtn.classList.remove('active');
            this.containerBtn.classList.add('active');
        });

        this.containerFluidBtn.addEventListener('click', () => {
            this.containerBtn.classList.remove('active');
            this.containerFluidBtn.classList.add('active');
        });

        document.querySelector('.fw-wrapper').appendChild(this.containerFluidBtn);
    }

    selectView() {
        if ($('.group-view-section').length) {
            this.viewSelectorContainer = document.createElement('div');
            this.viewSelectorContainer.classList.add('view-selecotor-container');
            let viewSelectorContent = `<label for="columnView">Prikaz sa side barom <input id="column-view" type="checkbox" name="columnView"></label>`;
            this.viewSelectorContainer.innerHTML = viewSelectorContent;
            document.querySelector('.fw-wrapper').appendChild(this.viewSelectorContainer);
            document.getElementById('column-view').addEventListener('click', () => {
                if (document.getElementById('column-view').checked == true) {
                    this.createColumnView();
                    if ($('.group_carousel_init').length) {
                        // $('.group_carousel_init').slick('refresh');
                        carousel.refresh();
                    }
                } else {
                    this.removeColumnView();
                }
            });
        }
    }

    createColumnView() {
        if ($('.group-view-section').length) {
            this.columnViewContainer = document.createElement('div')
            this.columnViewContainer.classList.add('container');
            this.columnViewContainer.setAttribute('id', 'column-container');

            let mainContent = document.getElementById('content');
            let groupSectionEl = document.querySelector('.group-view-section');
            groupSectionEl.remove();
            mainContent.insertBefore(this.columnViewContainer, document.querySelector('.contact-block-section'));
            this.columnRow = document.createElement('div');
            this.columnRow.classList.add('row')
            this.columnViewContainer.appendChild(this.columnRow);
            this.columnLeft = document.createElement('div');
            this.columnLeft.classList.add('col-9');
            this.columnLeft.setAttribute('id', 'column-left');
            this.columnRight = document.createElement('div');
            this.columnRight.classList.add('col-3');
            this.columnRight.setAttribute('id', 'column-right');
            this.columnRow.appendChild(this.columnLeft)
            this.columnRow.appendChild(this.columnRight);
            this.columnLeft.appendChild(groupSectionEl);

            if ($('.banners_wrapper').length) {
                let bannersWrapper = document.querySelector('.banners_wrapper');
                bannersWrapper.remove();
                this.columnRight.appendChild(bannersWrapper);
            }

            if ($('.documents-wrapper').length) {
                let documentsWrapper = document.querySelector('.documents-wrapper');
                documentsWrapper.remove();
                this.columnRight.appendChild(documentsWrapper);
            }

            if ($('.category-tabs').length) {
                let categoryTabs = document.querySelector('.category-tabs');
                categoryTabs.remove();
                this.columnRight.appendChild(categoryTabs);
            }

            if ($('#column-container').length) {
                this.containerBtn.addEventListener('click', () => {
                    document.getElementById('column-container').classList.remove('container-fluid');
                    document.getElementById('column-container').classList.add('container');
                });

                this.containerFluidBtn.addEventListener('click', () => {
                    document.getElementById('column-container').classList.remove('container');
                    document.getElementById('column-container').classList.add('container-fluid');
                });
            }
        }
    }

    removeColumnView() {
        document.getElementById('content').insertBefore(document.querySelector('.group-view-section'), document.querySelector('.contact-block-section'));

        let groupViewType = document.getElementById('groupViewSelection');
        let documentsWrapper = document.querySelector('.documents-wrapper');
        let gridComponentWrapper = document.querySelector('.grid_component_wrapper');
        let carouselComponentWrapper = document.querySelector('.carousel_component_wrapper');
        let listComponentWrapper = document.querySelector('.list_component_wrapper');
        if ($('.documents-wrapper').length) {
            if (groupViewType.options[groupViewType.selectedIndex].value == 'grid') {
                document.querySelector('.group-view-section').insertBefore(documentsWrapper, gridComponentWrapper);
            }

            if (groupViewType.options[groupViewType.selectedIndex].value == 'carousel') {
                document.querySelector('.group-view-section').insertBefore(documentsWrapper, carouselComponentWrapper);
            }

            if (groupViewType.options[groupViewType.selectedIndex].value == 'list') {
                document.querySelector('.group-view-section').insertBefore(documentsWrapper, listComponentWrapper);
            }
        }

        if ($('.banners_wrapper').length) {
            this.bannersContainer = document.createElement('div');
            this.bannersContainer.classList.add('container');
            this.bannersContainer.appendChild(document.querySelector('.banners_wrapper'));
            document.querySelector('.group-view-section').insertBefore(this.bannersContainer, document.querySelector('.documents-wrapper'));
        }

        if ($('.category-tabs').length) {
            this.categorytabsContainer = document.createElement('div');
            this.categorytabsContainer.classList.add('container');
            this.categorytabsRow = document.createElement('div');
            this.categorytabsRow.classList.add('row');
            this.categorytabsContainer.appendChild(this.categorytabsRow);
            this.categorytabsColumn = document.createElement('div');
            this.categorytabsColumn.classList.add('col-sm-12');
            this.categorytabsRow.appendChild(this.categorytabsColumn);
            this.categorytabsColumn.appendChild(document.querySelector('.category-tabs'));

            if (groupViewType.options[groupViewType.selectedIndex].value == 'grid') {
                document.querySelector('.category_wrapper').insertBefore(this.categorytabsContainer, gridComponentWrapper);
            }

            if (groupViewType.options[groupViewType.selectedIndex].value == 'carousel') {
                document.querySelector('.category_wrapper').insertBefore(this.categorytabsContainer, carouselComponentWrapper);
            }

            if (groupViewType.options[groupViewType.selectedIndex].value == 'list') {
                document.querySelector('.category_wrapper').insertBefore(this.categorytabsContainer, listComponentWrapper);
            }
        }

        this.sliderRefresh();
        this.itemHeightRefresh();
        document.getElementById('column-container').remove();
    }
}

export {
    FeatureWidget as default
}