import Componenets from './components/components';
import Modules from './modules/modules';
import polyfill from './_config/js/polyfill';
import {
    isInViewport,
    addToAnyInViewport
} from './utilities/viewport/viewport';

const components = new Componenets;
const modules = new Modules;

$.fn.isInViewport = isInViewport;

$(function () {
    polyfill();
    components.onDocumentReady();
    modules.onDocumentReady();
});

$(window).on('scroll', components.onWindowScroll);

components.onDocumentClick();

import FeatureWidget from './FeatureWidget/FeatureWidget';
$(function () {
    // zakomentirati da se isključi feature widget
    // const featureWidget = new FeatureWidget();
    // featureWidget.init();
    addToAnyInViewport();

    $(document).on('click', '.share-trigger', function (e) {
        e.preventDefault();
        $('.a2a_dd')[0].click();
    });

    $('body').delegate('.lb-override .pswp__button--addthis', 'click', function(e) {
        e.preventDefault();
        $('.a2a_dd')[0].click();
    });
});