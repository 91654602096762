class CommentsSlider {
  constructor(prevArw, nextArw) {
    this.options = {
      lazyLoad: 'ondemand',
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: prevArw,
      nextArrow: nextArw,
      adaptiveHeight: true,
      autoplay: true,
      dots: false,
    }
  }

  init() {
    $('.comments_slider_init').slick(this.options);
  }
  refresh() {
    $('.comments_slider_init').slick('refresh');
  }

}

export default CommentsSlider;