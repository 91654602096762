class SliderVOne {
  constructor(prevArw, nextArw) {
    this.options = {
      slidesToShow: 1,
      dots: false,
      infinite: false,
      speed: 800,
      fade: true,
      prevArrow: prevArw,
      nextArrow: nextArw,
      autoplay: true,
      adaptiveHeight: true,
    }
    this.windowCorrection = 17;
  }

  firstSliderInit() {
    $('.slider_init').slick(this.options);
  }

  firstSliderRefresh() {
    $('.slider_init').slick('refresh');
  }

  secondSliderInit() {
    $('.second_slider_init').slick(this.options);
  }

  secondSliderRefresh() {
    $('.second_slider_init').slick('refresh');
  }

  thirdSliderInit() {
    $('.third_slider_init').slick(this.options);
  }

  thirdSliderRefresh() {
    $('.third_slider_init').slick('refresh');
  }

  onInit() {
    let va = this.verticallyAlign;
    let winCorrection = this.windowCorrection;

    if ($('.slider_init').length) {
      $('.slider_init').on('init', function (slick) {
        va($(this), winCorrection);
      })
    }
    if ($('.second_slider_init').length) {
      $('.second_slider_init').on('init', function (slick) {
        va($(this), winCorrection);
      });
    }
    if ($('.third_slider_init').length) {
      $('.third_slider_init').on('init', function (slick) {
        va($(this), winCorrection);
      })
    }
  }
  verticallyAlign(item, winCorrection) {

    item.find('.slider_item').each(function (e) {
      var sliderImg = parseInt($(this).children('.slider-img').height());
      var sliderContent = parseInt($(this).children('.item-content').height());

      if (sliderImg < sliderContent) {
        $(this).removeClass('vertical-center');
      }
      if ($(this).hasClass('slider-item-right') && $(this).hasClass('vertical-center')) {
        if ($(window).width() > 769 - winCorrection) {

          var sliderHtml = $(this).html();
          var sliderImg = $(this).children('.slider-img');
          var sliderContent = $(this).children('.item-content');
          $(this).empty();
          $(this).append(sliderContent);
          $(this).append(sliderImg);
        }
      }


    })
  }

}

export default SliderVOne;